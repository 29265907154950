/* eslint-disable import/no-cycle */
import { api, TDApi } from './_api';
import { OrderFields, transformerIn, transformerOut } from './order.service';
import { NotificationHelper } from '../helpers/notification.helper';

const retryTimes = 120;
const retryWait = 5000;

// retry promise N times while waiting between each attempt
// until it resolves or rejects on the last attempt
const retryWithWait = (callback, times, wait) =>
    new Promise((resolve, reject) => {
        let error;
        const attempt = count =>
            callback()
                .then(resolve)
                .catch(err => {
                    // TODO: better handle error with constants etc.
                    // Also, BE should return proper error codes, like 404 for not found instead of 400
                    if (err.code === 400 || err.code === 404) {
                        NotificationHelper('error', 10, 'Error', 'BatchOrder not found');
                        error = new Error('BatchOrder not found');
                    } else if (err.code >= 500) {
                        NotificationHelper('error', 10, 'Error', 'Internal server error');
                        error = new Error('Internal server error');
                    } else if (err.code === 401) {
                        NotificationHelper('error', 10, 'Error', 'Unauthorized');
                        error = new Error('Unauthorized');
                    } else if (err.message === 'Network Error') {
                        NotificationHelper('error', 10, 'Error', 'Network Error');
                        error = new Error('Network Error');
                    }
                    if (error || count === times) {
                        reject(error);
                    } else {
                        setTimeout(() => attempt(count + 1), wait);
                    }
                });
        attempt(1);
    });

const get = id =>
    retryWithWait(
        async () => {
            const batch = await TDApi.get(`/batchOrder/${id}`);
            if (batch && batch.nrOrders === batch.orders.length) {
                const promises = batch.orders.map(order =>
                    api
                        .get(`/order/${String(order.orderNbr).padStart(6, '0')}/WP`)
                        .then(result => transformerIn(OrderFields, result))
                );
                const acumaticaOrders = await Promise.all(promises);
                batch.acumaticaOrders = acumaticaOrders;
                batch.totalPayment = batch.acumaticaOrders.reduce(
                    (accumulator, order) => accumulator + order.total,
                    0
                );
                batch.orderDetails = batch.orders;
                batch.orders = acumaticaOrders;
                batch.unpaidBalance = batch.acumaticaOrders.reduce(
                    (accumulator, order) => accumulator + order.unpaidBalance,
                    0
                );

                return batch;
            }
            throw new Error('Batch order not ready');
        },
        retryTimes,
        retryWait
    );

const create = values => TDApi.post('/batchOrder', values);
const update = async values => {
    const data = transformerOut(OrderFields, values);
    await TDApi.put(`/batchOrder/${values.id}`, data);

    return retryWithWait(
        async () => {
            const batch = await TDApi.get(`/batchOrder/${values.id}`);
            if (batch && batch.step.toString() === values.activeStep.toString()) {
                const promises = batch.orders.map(order =>
                    api
                        .get(`/order/${String(order.orderNbr).padStart(6, '0')}/WP`)
                        .then(result => transformerIn(OrderFields, result))
                );
                const acumaticaOrders = await Promise.all(promises);
                batch.acumaticaOrders = acumaticaOrders;
                batch.totalPayment = batch.acumaticaOrders.reduce(
                    (accumulator, order) => accumulator + order.total,
                    0
                );
                batch.orderDetails = batch.orders;
                batch.orders = acumaticaOrders;
                return batch;
            }
            throw new Error('Batch order not ready');
        },
        retryTimes,
        retryWait
    );
};

/* eslint no-promise-executor-return: "off" */
const payment = (id, type, values) =>
    new Promise((resolve, reject) =>
        api.post(`/order/${id}/${type}/batchPayment`, values).then(
            order => resolve(transformerIn(OrderFields, order)),
            error => reject(error)
        )
    );

export const BatchOrderService = {
    get,
    create,
    update,
    payment,
};
