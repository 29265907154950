/* eslint-disable import/no-cycle */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TDApi as api } from './_api';
import { parseSingleItem } from '../helpers/items.parser';

dayjs.extend(utc);

const patchDocument = (path, value) => [
    {
        value,
        path,
        op: 'replace',
    },
];

export const transformerInvData = data => {
    return {
        ...data,
        mountDueBy: data.mountDueBy ? dayjs.utc(data.mountDueBy) : undefined,
        dateOfKill: data.dateOfKill ? dayjs.utc(data.dateOfKill) : undefined,
        timeOfKill: data.timeOfKill
            ? dayjs(dayjs.utc(data.timeOfKill).toDate()).local()
            : undefined,
        receivedTanned: data.receivedTanned ? dayjs.utc(data.receivedTanned) : undefined,
    };
};

const list = () => api.get('/inventory');

const listByUserId = userId => api.get(`/inventory/user/${userId}`);

const getItem = id => api.get(`/inventory/item/${id}`).then(item => transformerInvData(item));

const getItemForAdmin = id =>
    api.get(`/inventory/admin/item/${id}`).then(item => transformerInvData(item));

const createItem = values =>
    api.post('/inventory/item', values).then(item => transformerInvData(item));

const editItem = values => api.put(`/inventory/item/${values.id}`, values);

const editItemByAdmin = values => api.put(`/inventory/admin/item/${values.id}`, values);

const deleteItem = id => api.delete(`/inventory/item/${id}`);

const deleteItemByAdmin = id => api.delete(`/inventory/admin/item/${id}`);

const isTagAvailable = id => api.get(`/inventory/tag/${id}/available`).then(result => result);

const transitionItemsToOrder = (order, inventoryItems) =>
    api
        .post(
            `/inventory/order/${order.number}`,
            inventoryItems.map(x => x.id)
        )
        .then(() => order);

const getItemByTag = (tag, config) =>
    api.post(`/inventory/item/tag/${tag}`, config).then(result => parseSingleItem(result));

const getItemByTagForAdmin = tag =>
    api.get(`/inventory/admin/item/tag/${tag}`).then(result => result);

const updateItemStatus = (id, statusToUpdate) =>
    new Promise((resolve, reject) => {
        const patchedDocument = patchDocument('/status', statusToUpdate);

        api.patch(`/inventory/item/${id}`, patchedDocument).then(
            result => resolve(result),
            error => reject(error)
        );
    });

const deleteItemImage = id => api.delete(`/inventory/image/${id}`);

const getItemsForFishAndWildlifeReport = values =>
    api.post('/inventory/fishAndWildlife', values).then(result => result);

const getNewItemsForCustomer = values =>
    api.post('/inventory/admin/import', values).then(result => result);

const createItemFeedback = values => api.post(`/inventory/item/${values.id}/feedback`, values);

const updateItemsPrices = values => api.post('/inventory/items/prices', values);

export const InventoryService = {
    list,
    listByUserId,
    getItem,
    createItem,
    editItem,
    editItemByAdmin,
    deleteItem,
    deleteItemByAdmin,
    isTagAvailable,
    transitionItemsToOrder,
    getItemByTag,
    updateItemStatus,
    deleteItemImage,
    getItemsForFishAndWildlifeReport,
    getItemByTagForAdmin,
    getItemForAdmin,
    getNewItemsForCustomer,
    createItemFeedback,
    updateItemsPrices,
};
