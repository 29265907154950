import { BEAVER_ITEM_ID_TO_20 } from '../models';

function parseItemsSpecies(items) {
    const species = items
        .map(item => {
            return { value: item.specie, label: item.specie };
        })
        .filter((item, index, self) => {
            return index === self.findIndex(t => t.value === item.value);
        });

    species.sort((a, b) => (a.value < b.value ? -1 : 1));
    return species;
}

function parseSingleItem(item) {
    if (
        item.inventoryItemCreateDto &&
        item.inventoryItemCreateDto.inventoryId === BEAVER_ITEM_ID_TO_20
    ) {
        return {
            ...item,
            inventoryItemCreateDto: {
                ...item.inventoryItemCreateDto,
                item: 'Beaver Dressing',
                speciesItem: 'Dressing',
            },
        };
    }

    if (item.inventoryId === BEAVER_ITEM_ID_TO_20) {
        return { ...item, item: 'Beaver Dressing', speciesItem: 'Dressing' };
    }

    return item;
}

function parseItemsList(items) {
    return items.map(item => {
        return parseSingleItem(item);
    });
}

export { parseItemsSpecies, parseSingleItem, parseItemsList };
