import { BatchOrderConstants as constants } from '../constants';
import { BatchOrderStatus, ORDER_TYPES, OrderStatus } from '../models';

const initialState = Object.freeze({
    type: ORDER_TYPES.WP,
    pickup: false,
    nrOrders: 0,
    orderDetails: [],
    orders: [],
    serviceTotal: false,
    status: OrderStatus.enRoute,
    step: BatchOrderStatus.Incomplete,
    totalPayment: 0,
    unpaidBalance: 0,
    paid: false,
    payByCheck: false,
    numberOfBoxes: null,
    hold: false,
    laceyAct: false,
    refresh: 0,
    // volumeDiscounts: [],????? and taxes???

    orderError: false,

    ordersLoading: false,
    orderUpdating: false,
    orderCreating: false,

    batchOrderUpdating: false,
    batchOrderLoading: false,
});

const batchOrder = (state = initialState, action) => {
    switch (action.type) {
        case constants.BATCH_ORDER_CLEAR: {
            return {
                ...initialState,
            };
        }

        case constants.BATCH_ORDER_LOADING_START: {
            return {
                ...state,
                batchOrderLoading: true,
                error: false,
            };
        }
        case constants.BATCH_ORDER_LOADING_ERROR: {
            return {
                ...state,
                batchOrderLoading: false,
                orderError: action.error,
            };
        }
        case constants.BATCH_ORDER_LOADED: {
            return {
                ...state,
                ...action.batchOrder,
                orders: action.batchOrder.orders,
                orderDetails: action.batchOrder.orderDetails,
                status: { step: action.batchOrder.step },
                payByCheck: action.batchOrder.isCheckOnly,
                paid: action.batchOrder.acumaticaOrders.every(
                    o => o.payments.length > 0 || o.payByCheck
                ),
                numberOfBoxes: action.batchOrder.boxes,
                batchOrderLoading: false,
                orderError: false,
                refresh: state.refresh + 1,
                totalPayment: action.batchOrder.totalPayment,
                unpaidBalance: action.batchOrder.acumaticaOrders.reduce(
                    (accumulator, order) => accumulator + order.unpaidBalance,
                    0
                ),
                error: false,
            };
        }
        case constants.BATCH_ORDER_CREATING_START: {
            return {
                ...initialState,
                orderCreating: true,
                error: false,
            };
        }
        case constants.BATCH_ORDER_CREATED: {
            return {
                ...state,
                ...action.batchOrder,
                orderCreating: false,
                error: false,
            };
        }
        case constants.BATCH_ORDER_CREATING_ERROR: {
            return {
                ...initialState,
                orderCreating: false,
                error: action.error,
            };
        }
        case constants.BATCH_ORDER_UPDATING_START: {
            return {
                ...state,
                orderUpdating: true,
                batchOrderUpdating: true,
                error: false,
            };
        }
        case constants.BATCH_ORDER_UPDATING_ERROR: {
            return {
                ...state,
                orderUpdating: false,
                batchOrderUpdating: false,
                error: action.error,
            };
        }
        // case constants.BATCH_ORDER_PAID:
        case constants.BATCH_ORDER_UPDATED: {
            const newValue = action.batchOrder.detailsConfirmed;
            const detailsConfirmed = newValue === undefined ? state.detailsConfirmed : newValue;
            return {
                ...state,
                ...action.batchOrder,
                orders: action.batchOrder.orders,
                orderDetails: action.batchOrder.orderDetails,
                // status: { step: action.batchOrder.step },
                payByCheck: action.batchOrder.isCheckOnly,
                numberOfBoxes: action.batchOrder.boxes,
                orderUpdating: false,
                batchOrderUpdating: false,
                refresh: state.refresh + 1,
                totalPayment: action.batchOrder.totalPayment,
                unpaidBalance: action.batchOrder.acumaticaOrders?.reduce(
                    (accumulator, order) => accumulator + order.unpaidBalance,
                    0
                ),
                paid:
                    action.batchOrder.acumaticaOrders &&
                    action.batchOrder.acumaticaOrders[0].payments.length > 0,
                error: false,
                detailsConfirmed,
            };
        }
        default:
            return state;
    }
};

export { batchOrder };
