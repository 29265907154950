import { parseItemsList, parseItemsSpecies, parseSingleItem } from '../helpers/items.parser';

import { InventoryConstants as constants } from '../constants';

const initialState = Object.freeze({
    items: [],
    filters: {},
    item: null,
    loading: false,
    error: false,
    itemCreating: false,
    itemLoading: false,
    itemDeleting: false,
    itemStatusUpdating: false,
    itemImageName: '',
    itemImageUploading: false,
    itemsFishAndWildlife: [],
    itemsFishAndWildlifeLoading: false,
    itemsFishAndWildlifeError: false,
    scannedItems: [],
    itemsImporting: false,
    importedItems: [],
    itemsImportingError: null,
    itemFeedbackCreating: false,
    itemFeedbackCreatingError: null,
    itemsSpecies: [],
});

const inventory = (state = initialState, action) => {
    switch (action.type) {
        case constants.INVENTORY_CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.INVENTORY_LOADING_START: {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }
        case constants.INVENTORY_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case constants.INVENTORY_LOADED: {
            return {
                ...state,
                items: parseItemsList(action.items),
                loading: false,
                error: false,
                itemsSpecies: parseItemsSpecies(action.items),
            };
        }
        case constants.INVENTORY_ITEM_LOADING_START: {
            return {
                ...state,
                item: null,
                itemLoading: true,
                error: false,
            };
        }
        case constants.INVENTORY_ITEM_LOADING_ERROR: {
            return {
                ...state,
                itemLoading: false,
                error: action.error,
            };
        }
        case constants.INVENTORY_ITEM_LOADED: {
            return {
                ...state,
                item: parseSingleItem(action.item),
                itemLoading: false,
                error: false,
            };
        }
        case constants.INVENTORY_ITEM_UNLOADED: {
            return {
                ...state,
                item: null,
                itemLoading: false,
                error: false,
            };
        }
        case constants.INVENTORY_ITEM_CREATING_START: {
            return {
                ...state,
                itemCreating: true,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_CREATED: {
            return {
                ...state,
                item: parseSingleItem(action.item),
                itemCreating: false,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_CREATING_ERROR: {
            return {
                ...state,
                item: null,
                itemCreating: false,
                error: action.error,
            };
        }
        case constants.INVENTORY_ITEM_EDITING_START: {
            return {
                ...state,
                itemEditing: true,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_EDITED: {
            return {
                ...state,
                itemEditing: false,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_EDITING_ERROR: {
            return {
                ...state,
                item: null,
                itemEditing: false,
                error: action.error,
            };
        }
        case constants.INVENTORY_ITEM_DELETING_START: {
            return {
                ...state,
                itemDeleting: true,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_DELETED: {
            return {
                ...state,
                item: null,
                itemDeleting: false,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_DELETING_ERROR: {
            return {
                ...state,
                item: null,
                itemDeleting: false,
                error: action.error,
            };
        }
        case constants.INVENTORY_ITEM_UPDATING_STATUS_START: {
            return {
                ...state,
                itemStatusUpdating: true,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_STATUS_UPDATED: {
            return {
                ...state,
                itemStatusUpdating: false,
                error: null,
            };
        }
        case constants.INVENTORY_ITEM_UPDATING_STATUS_ERROR: {
            return {
                ...state,
                itemStatusUpdating: false,
                error: action.error,
            };
        }
        case constants.INVENTORY_ADD_FILTERS: {
            return {
                ...state,
                filters: action.filters,
            };
        }
        case constants.INVENTORY_CLEAR_FILTERS: {
            return {
                ...state,
                filters: {},
            };
        }
        case constants.INVENTORY_ITEM_FISH_AND_WILDLIFE_ERROR: {
            return {
                ...state,
                itemsFishAndWildlifeLoading: false,
                itemsFishAndWildlifeError: action.error,
            };
        }
        case constants.INVENTORY_ITEM_FISH_AND_WILDLIFE_LOADING_START: {
            return {
                ...state,
                itemsFishAndWildlifeLoading: true,
                itemsFishAndWildlifeError: null,
            };
        }
        case constants.INVENTORY_ITEM_FISH_AND_WILDLIFE_LOADED: {
            return {
                ...state,
                itemsFishAndWildlife: action.items,
                itemsFishAndWildlifeLoading: false,
                itemsFishAndWildlifeError: null,
            };
        }
        case constants.INVENTORY_SCANNED_ITEMS_UPDATE: {
            return {
                ...state,
                scannedItems: action.scannedItems,
            };
        }

        case constants.INVENTORY_ITEMS_IMPORTING_START: {
            return {
                ...state,
                itemsImporting: true,
                itemsImportingError: null,
            };
        }
        case constants.INVENTORY_ITEMS_IMPORTED: {
            return {
                ...state,
                importedItems: action.importedItems.items,
                itemsImporting: false,
                itemsImportingError: action.importedItems.errorMessage,
            };
        }
        case constants.INVENTORY_ITEMS_IMPORTING_ERROR: {
            return {
                ...state,
                importedItems: null,
                itemsImporting: false,
                itemsImportingError: action.importedItems.errorMessage,
            };
        }
        case constants.INVENTORY_ITEMS_IMPORTED_CLEAR: {
            return {
                ...state,
                importedItems: [],
                itemsImporting: false,
                itemsImportingError: null,
            };
        }

        case constants.INVENTORY_SCANNED_ITEMS_RESET: {
            return {
                ...state,
                scannedItems: [],
            };
        }

        case constants.INVENTORY_ITEM_FEEDBACK_CREATING_START: {
            return {
                ...state,
                itemFeedbackCreating: true,
                error: null,
            };
        }

        case constants.INVENTORY_ITEM_FEEDBACK_CREATED: {
            return {
                ...state,
                itemFeedbackCreating: false,
                error: null,
            };
        }

        case constants.INVENTORY_ITEM_FEEDBACK_CREATING_ERROR: {
            return {
                ...state,
                itemFeedbackCreating: false,
                error: action.error,
            };
        }

        default:
            return state;
    }
};

export { inventory };
