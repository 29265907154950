/* eslint-disable max-len */

import { ReactComponent as FortyDaysServiceIcon } from '../assets/fortyDays-service.svg';
import { ReactComponent as NinetyDaysServiceIcon } from '../assets/ninetyDays-service.svg';
import { ReactComponent as StandardServiceIcon } from '../assets/standard-service.svg';
import { showBrief } from './user.model';

const noItems = order => order.items?.filter(i => i.id.length).length === 0;

const detailsDisabled = () => false;
const licenseDisabled = order => noItems(order) || !order.detailsConfirmed;
const laceyDisabled = order => {
    if (showBrief(order)) {
        if (!order.licenseNotes) {
            return true;
        }

        return licenseDisabled(order);
    }

    return (
        licenseDisabled(order) || !order.customerType || !order.licenseState || !order.licenseStatus
    );
};

const deliveryDisabled = order => laceyDisabled(order) || !order.laceyAct;
const noShippingChosen = order => order.shippingTerms === null;
const paymentDisabled = order => deliveryDisabled(order) || noShippingChosen(order);
const shippingDisabled = order =>
    paymentDisabled(order) || !(order.payments && order.payments.length) || order.payByCheck;
const trackingDisabled = order => shippingDisabled(order) || !order.printedDocs;

export const BEAVERM20 = 'Beaver Hide (Qty. 21 or more)';

export const BEAVER_ITEM_ID_TO_20 = 'BEAVER';

export const BEAVERM20_SERVICE = { name: 'BEAVERM20', description: 'Beaver Dressing' };

export const DEFAULT_BEAVER_TYPES = {
    BEAVERXLG: {
        label: 'BEAVERXLG',
        fakeId: `${-1}BEAVERXLG`,
    },
    BEAVERLG: {
        label: 'BEAVERLG',
        fakeId: `${-2}BEAVERLG`,
    },
    BEAVERSM: {
        label: 'BEAVERSM',
        fakeId: `${-3}BEAVERSM`,
    },
};

export const DEFAULT_BEAVER_TYPES_IDS = ['BEAVERXLG', 'BEAVERSM', 'BEAVERLG'];

export const WP_DISCOUNT_5 = 'DISCOUNT5';
export const WP_DISCOUNT_15 = 'DISCOUNT15';

export const DEFAULT_BEAVER_SERVICES = [
    {
        speciesItem: DEFAULT_BEAVER_TYPES.BEAVERXLG.label,
        id: DEFAULT_BEAVER_TYPES.BEAVERXLG.fakeId,
        specie: 'Beaver',
        acumaticaInventoryId: 'BEAVERXLG',
        qty: 0,
        marks: [],
        speciesItemDescription: 'Dressing (XL 60+ in qty 21+)',
        delete: false,
        $new: true,
    },
    {
        speciesItem: DEFAULT_BEAVER_TYPES.BEAVERLG.label,
        id: DEFAULT_BEAVER_TYPES.BEAVERLG.fakeId,
        specie: 'Beaver',
        acumaticaInventoryId: 'BEAVERLG',
        qty: 0,
        marks: [],
        speciesItemDescription: 'Dressing (L 51-60 in qty 21+)',
        delete: false,
        $new: true,
    },
    {
        speciesItem: DEFAULT_BEAVER_TYPES.BEAVERSM.label,
        id: DEFAULT_BEAVER_TYPES.BEAVERSM.fakeId,
        specie: 'Beaver',
        acumaticaInventoryId: 'BEAVERSM',
        qty: 0,
        speciesItemDescription: 'Dressing (S less 51 in qty 21+)',
        marks: [],
        delete: false,
        $new: true,
    },
];

export const ORDER_TYPES = {
    SO: 'SO',
    WO: 'WO',
    WP: 'WP',
};

export const CANCELED = 'Canceled';

export const OrderSteps = {
    details: {
        key: 1,
        disabled: detailsDisabled,
        title: '1. Order Details',
    },
    license: {
        key: 2,
        disabled: licenseDisabled,
        title: '2. License/Permit Details',
    },
    lacey: {
        key: 3,
        disabled: laceyDisabled,
        title: '3. Lacey Act',
    },
    delivery: {
        key: 4,
        disabled: deliveryDisabled,
        title: '4. Finished Order Shipping/Pick-up Details',
    },
    payment: {
        key: 5,
        disabled: paymentDisabled,
        title: '5. Payment',
    },
    shipping: {
        key: 6,
        disabled: shippingDisabled,
        title: '6. Shipping to Tannery',
    },
    tracking: {
        key: 7,
        disabled: trackingDisabled,
        title: '7. Track Your Order',
    },
};

export const Config = {
    tooltips: {
        marks: 'Here you can see/add your inventory code and/or government tag numbers.',
    },
    messages: {
        beaverL5: 'You are not allowed to add more than 5 Beavers of that type!', // @deprecated since 1.10.0
        beaverM5: 'You are not allowed to add less than 5 Beavers of that type!', // @deprecated since 1.10.0
        beaverL20: 'You are not allowed to add more than 20 Beavers of that type!',
        beaverM20:
            'Continue adding Beaver of different sizes to reach total quantity of 21 or more.',
        beaverL21: 'You are not allowed to add less than 21 Beavers of that type!',
        beaverTwoTypes: 'You are not allowed to add two types of Beavers',
        beaverTwoTypesShort: 'One type of Beaver!',
        invalid: 'Invalid value',
        alreadyAdded: 'Already added!',
        twoOfKind:
            "This item has already been added to your order. If you wish to add more of that item to the order, please modify it's quantity accordingly.",
    },
    specie: {
        beaver: BEAVER_ITEM_ID_TO_20,
    },
    tanningTypes: {
        dressing: 'Dressing',
    },
    fields: {
        qty: 'qty',
        specie: 'specie',
        speciesItem: 'speciesItem',
        tanningType: 'tanningType',
        prepType: 'prepType',
        finishType: 'finishType',
        marks: 'marks',
        itemShortDescription: 'itemShortDesc',
        speciesItemDescription: 'speciesItemDescription',
    },
};

export const OrderStatus = {
    uncomplete: {
        title: 'Order entry incomplete',
        color: '#28B5E1',
        textColor: 'white',
        icon: '/images/steps/packaging.png',
        step: OrderSteps.details.key,
        substep: -2,
    },
    shipmentUnconfirmed: {
        title: 'Shipment Unconfirmed',
        color: '#e12874',
        textColor: 'white',
        icon: '/images/steps/packaging.png',
        step: OrderSteps.shipping.key,
        substep: -1,
    },
    enRoute: {
        title: 'En Route to MM&T',
        color: '#f18500',
        textColor: 'white',
        icon: '/images/steps/awaiting_goods.png',
        step: OrderSteps.tracking.key,
        substep: 0,
    },
    atmmt: {
        title: 'Goods at MM&T',
        color: '#ff2626',
        textColor: 'white',
        icon: '/images/steps/goods_at_mmt.png',
        step: OrderSteps.tracking.key,
        substep: 1,
    },
    complete: {
        title: 'Order completed',
        color: '#4cb8a8',
        textColor: 'white',
        icon: '/images/steps/goods_shipped.png',
        step: OrderSteps.tracking.key,
        substep: 2,
    },
};

export const OrderItemStatus = {
    ready: 0, // Not yet received
    received: 1,
    processing: 2,
    complete: 3, // Finished
    invoiced: 4,
    discarded: 5,
};

export const OrderItemStatusEnum = {
    0: { label: 'Not Yet Received', value: 0 },
    1: { label: 'Received', value: 1 },
    2: { label: 'Processing', value: 2 },
    3: { label: 'Finished', value: 3 },
    4: { label: 'Invoiced', value: 4 },
    5: { label: 'Discarded', value: 5 },
};

export const OrderItemDefault = {
    specie: '',
    tanningType: '',
    prepType: undefined,
    finishType: undefined,
    speciesItem: '',
    qty: '',
    marks: [],
    delete: false,
};

export const ORDER_SERVICE_TYPES = {
    express: {
        name: 'express',
        key: 'RUSH',
        title: '40-Day Rush Order: 50% surcharge',
        titleForSummary: '40-Day Rush 50% Surcharge',
        titleForPage: '40-Day Rush',
        userField: 'orderExpressId',
        acumaticaType: 'Rush',
        icon: FortyDaysServiceIcon,
        phpServiceType: 'Rush40Days',
    },
    regular: {
        name: 'regular',
        key: 'regular',
        title: 'Standard',
        titleForSummary: 'Standard',
        titleForPage: 'Standard',
        userField: 'orderId',
        acumaticaType: 'Standard',
        icon: StandardServiceIcon,
        phpServiceType: 'Standard',
    },
    guaranteed: {
        name: 'guaranteed',
        key: 'GUARANTEED',
        title: '90-Day Guarantee: 10% surcharge',
        titleForSummary: '90-Day Guarantee 10% Surcharge',
        titleForPage: '90-Day Guarantee',
        userField: 'orderGuaranteedId',
        acumaticaType: 'Guaranteed',
        icon: NinetyDaysServiceIcon,
        phpServiceType: 'Guaranteed90Days',
    },
};

export const ORDER_MARK_NAMES = {
    MARK1: 'mark1',
    MARK2: 'mark2',
};

export const isDelivery = values => values && values.shippingTerms === true;
export const isPickup = values => values && values.shippingTerms === false;
export const isPredefinedAddress = values => !!values.locationId;
